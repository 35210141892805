import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { Button, Input, message, Modal, Select } from 'antd';
import { debounce, remove } from 'lodash';

// Components
import Loading from 'components/Loading';

// Interfaces
import { IQueryParam } from 'interfaces/common.interface';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { getAllType, removeType } from 'store/global/action';

// Constants
import { INIT_PAGINATION } from 'constants/common.constant';

// Helpers
import { getUniqueKey } from 'helpers/common.helper';

// Enums
import { EKind, ESubKind } from 'enums/common.enum';

const { Option } = Select;

const Type = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IQueryParam>({
    page: INIT_PAGINATION.PAGE,
    limit: INIT_PAGINATION.LIMIT
  });
  const [pagination, setPagination] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showDeleteType, setShowDeleteType] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(null);

  const fetchData = async () => {
    try {
      setLoading(true);

      const res: any = await unwrapResult(await dispatch(getAllType(params)));

      console.log(res.items);

      if (res && res.items && res.pagination) {
        setData([...data, ...res.items]);
        setPagination(res.pagination);
      } else {
        setData([...[]]);
        setPagination(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const fetchMoreData = () => {
    if (params.page && params.page < pagination.totalPages) {
      setParams({ ...params, page: params.page + 1 });
    }
  };

  const handleRemoveType = (id: string) => {
    setDeleteId(id);
    setShowDeleteType(true);
  }

  const resetRemoveModal = () => {
    setDeleteId(null);
    setShowDeleteType(false);
  }

  const handleCancelRemoveType = () => {
    resetRemoveModal();
  }

  const handleOkRemoveType = async () => {
    try {
      await unwrapResult(await dispatch(removeType(deleteId)));
      remove(data, item => item._id === deleteId);
      setData([...data]);
      message.success('Deleted type successfully');
      resetRemoveModal();
    } catch (e) {
      console.log(e);
    }
  }

  const resetData = () => {
    setData([...[]]);
    setPagination(null);
  }

  const searching = (e: any) => {
    resetData();

    if (e.target.value.trim() !== '') {
      setParams({
        ...params,
        keyword: e.target.value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    } else {
      setParams({
        ...params,
        keyword: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    }
  };

  const selectLevel = (value: any) => {
    resetData();

    if (value !== '') {
      setParams({
        ...params,
        level: value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    } else {
      setParams({
        ...params,
        level: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    }
  };

  const selectType = (value: any) => {
    resetData();

    if (value !== '') {
      setParams({
        ...params,
        kind: value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    } else {
      setParams({
        ...params,
        kind: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    }
  };

  const selectSubType = (value: any) => {
    resetData();

    if (value !== '') {
      setParams({
        ...params,
        subKind: value,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    } else {
      setParams({
        ...params,
        subKind: null,
        page: INIT_PAGINATION.PAGE,
        limit: INIT_PAGINATION.LIMIT
      });
    }
  };

  useEffect(() => {
    if (params) {
      fetchData();
    }
  }, [params]);

  useEffect(() => {
    if (pagination) {
      if (pagination.currentPage < pagination.totalPages) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [pagination]);

  return (
    <>
      <div className="content user-content">
        <Row>
          <div className="multiple-fields">
            <div className="multiple-fields__item">
              <Input
                className="default"
                placeholder="Search..."
                allowClear
                onChange={debounce(searching, 1000)}
              />
            </div>

            <div className="multiple-fields__item">
              <Select
                placeholder="Select level"
                className="default"
                allowClear
                onChange={selectLevel}
              >
                {Array.from(Array(100).keys()).map((item, index) => (
                  <Option key={getUniqueKey()} value={item}>Level {item}</Option>
                ))}
              </Select>
            </div>

            <div className="multiple-fields__item">
              <Select
                placeholder="Select type"
                className="default"
                allowClear
                onChange={selectType}
              >
                <Option value={EKind.Main}>Main</Option>
                <Option value={EKind.Special}>Special</Option>
              </Select>
            </div>

            <div className="multiple-fields__item">
              <Select
                placeholder="Select sub type"
                className="default"
                allowClear
                onChange={selectSubType}
              >
                <Option value={ESubKind.Energizing}>Energizing</Option>
                <Option value={ESubKind.Seasonal}>Seasonal</Option>
                <Option value={ESubKind.NatureSpirit}>Nature Spirit</Option>
                <Option value={ESubKind.CitySpirit}>City Spirit</Option>
                <Option value={ESubKind.PreciousStones}>Precious Stones</Option>
                <Option value={ESubKind.Celebrities}>Celebrities</Option>
                <Option value={ESubKind.Painters}>Painters</Option>
                <Option value={ESubKind.Mystics}>Mystics</Option>
                <Option value={ESubKind.FictionalCharacters}>Fictional Characters</Option>
                <Option value={ESubKind.MysticalCreatures}>Mystical Creatures</Option>
                <Option value={ESubKind.AnimalSpirit}>Animal Spirit</Option>
              </Select>
            </div>
          </div>
        </Row>

        <Row>

          <Col md="12">
            {data && data.length ? (
              <Card>
                <CardBody>
                  <InfiniteScroll
                    dataLength={data.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={null}
                    endMessage={<p className="all-items-txt">You have seen all items</p>}
                  >
                    <Table responsive>
                      <thead className="text-primary">
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Level</th>
                          <th>Color</th>
                          <th>Type</th>
                          <th>Sub Type</th>
                          <th>Created At</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item, index) => (
                          <tr key={getUniqueKey()}>
                            <td className="avatar">
                              <img alt="photo" src={item.image ? item.image : require('assets/img/default.jpeg')} />
                            </td>

                            <td className="link">
                              <a href={`type/${item._id}`}>{item.name}</a>
                            </td>

                            <td>{item.level}</td>

                            <td className="hex-color">
                              <div style={{
                                background: item.color,
                              }}></div>
                            </td>

                            <td>
                              {item.kind === EKind.Main && 'Main'}
                              {item.kind === EKind.Special && 'Special'}
                            </td>

                            <td>
                              {item.subKind === ESubKind.Energizing && 'Energizing'}
                              {item.subKind === ESubKind.Seasonal && 'Seasonal'}
                              {item.subKind === ESubKind.NatureSpirit && 'NatureSpirit'}
                              {item.subKind === ESubKind.CitySpirit && 'City Spirit'}
                              {item.subKind === ESubKind.PreciousStones && 'Precious Stones'}
                              {item.subKind === ESubKind.Celebrities && 'Celebrities'}
                              {item.subKind === ESubKind.Painters && 'Painters'}
                              {item.subKind === ESubKind.Mystics && 'Mystics'}
                              {item.subKind === ESubKind.FictionalCharacters && 'Fictional Characters'}
                              {item.subKind === ESubKind.MysticalCreatures && 'Mystical Creatures'}
                              {item.subKind === ESubKind.AnimalSpirit && 'Animal Spirit'}
                            </td>

                            <td>
                              {moment(item.createdAt).format('YYYY-MM-DD')}
                            </td>

                            <td className="text-right action link">
                              <a href={`type/${item._id}`}>
                                <i className="fas fa-pen"></i>
                              </a>

                              <i
                                className="fas fa-trash"
                              onClick={() => handleRemoveType(item._id)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </CardBody>
              </Card>
            ) : (
              <p className="all-items-txt">No items.</p>
            )}

            {loading && <Loading />}
          </Col>
        </Row>
      </div>

      <Modal
        title="Delete"
        open={showDeleteType}
        onOk={handleOkRemoveType}
        onCancel={handleCancelRemoveType}
        footer={[
          <Button
            key="back"
            className="btn modal-btn"
            onClick={handleCancelRemoveType}
          >Cancel</Button>,
          <Button
            className="btn btn-primary modal-btn"
            key="submit"
            onClick={handleOkRemoveType}
          >OK</Button>,
        ]}
      >
        <p>Do you want to remove this type?</p>
      </Modal>
    </>
  );
}

export default Type;
