import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './Loading.scss';

const Loading = () => {
  return (
    <>
      <div className="loading-content">
        <Spin indicator={<LoadingOutlined  spin />} />
      </div>
    </>
  )
}

export default Loading;