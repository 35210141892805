import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Public = (Component: any) => {
  const WithPublic = ({ props }: { props: any }) => {
    const navigate = useNavigate();

    useEffect(() => {
      if (props && props.me) {
        navigate('/');
      }
    }, [props]);

    return <Component {...props} />;
  }

  return WithPublic;
};