import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// Store
import { useAppDispatch } from 'store/hooks';
import { storeMe } from 'store/global/action';

// Helpers
import { removeToken } from 'helpers/common.helper';

import routes from '../../routes';

const Header = (props: any) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState('transparent');
  const sidebarToggle = React.useRef(null);
  const location = useLocation();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e: any) => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    let brandName = '';

    routes.map((item) => {
      if (window.location.href.indexOf(item.path) !== -1) {
        brandName = item.name;
      }
      return null;
    });

    return brandName;
  };

  const openSidebar = () => {
    // document.documentElement.classList.toggle("nav-open");
    // sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  const handleLogOut = () => {
    dispatch(storeMe(undefined));
    removeToken();
    navigate('/login');
  }

  useEffect(() => {
    window.addEventListener('resize', updateColor.bind(this));
  });

  useEffect(() => {
    // if (
    //   window.innerWidth < 993 &&
    //   document.documentElement.className.indexOf("nav-open") !== -1
    // ) {
    //   document.documentElement.classList.toggle("nav-open");
    //   sidebarToggle.current.classList.toggle("toggled");
    // }
  }, [location]);

  return (
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
          (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>

        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e: any) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="fas fa-user"></i>

                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>

              <DropdownMenu right>
                <DropdownItem onClick={handleLogOut} tag="a">Log Out</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
