class Storage {

  private prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  public set(key: string, data: any) {
    localStorage.setItem(
      this.getKey(key),
      JSON.stringify(data)
    );
  }

  public get(key: string) {
    const data = localStorage.getItem(this.getKey(key));
    try {
      return data ? JSON.parse(data) : null;
    } catch (e) {
      return null;
    }
  }

  public remove(key: string) {
    return localStorage.removeItem(this.getKey(key));
  }

  private getKey(key: string) {
    return `${this.prefix}_${key}`;
  }

}

export default new Storage(process.env.REACT_APP_STORAGE_PREFIX || 'beem');