import React from 'react';

// Pages
import Dashboard from 'pages/Dashboard';
import User from 'pages/User';
import Type from 'pages/Type';
import Manager from 'pages/Manager';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'far fa-chart-bar',
    component: <Dashboard />,
    layout: '/admin',
  },
  {
    path: '/user',
    name: 'User',
    icon: 'fas fa-user',
    component: <User />,
    layout: '/admin',
  },
  {
    path: '/type',
    name: 'Type',
    icon: 'fas fa-list-ul',
    component: <Type />,
    layout: '/admin',
  },
  {
    path: '/beam',
    name: 'Beam',
    icon: 'fas fa-bolt',
    component: <Dashboard />,
    layout: '/admin',
  },
  {
    path: '/manager',
    name: 'Manager',
    icon: 'fas fa-user-cog',
    component: <Manager />,
    layout: '/admin',
  },
];

export default routes;
