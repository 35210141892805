import { blobToBase64, getCroppedImg } from 'helpers/common.helper';
import { Button } from 'antd';
import React, { useState } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';

import 'react-image-crop/src/ReactCrop.scss';
import './CropImage.scss';

type IProps = {
  onSelect: (data: any) => any;
}

const CropImage = ({ onSelect }: IProps) => {
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [inputImage, setInputImage] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);

  const handleSelect = async () => {
    const base64Image = await blobToBase64(croppedImage);
    onSelect(base64Image);
    setInputImage(null);
    setCroppedImage(null);
  }

  const handleRemove = () => {
    setInputImage(null);
    setCroppedImage(null);
  }

  const handleComplete = async (data: PixelCrop) => {
    const croppedImage = await getCroppedImg(inputImage, data);
    setCroppedImage(croppedImage);
  }

  const handleInputChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      setInputImage(reader.result);
    }, false);
  }

  return (
    <>
      <div className="crop-image-content">
        {inputImage ? (
          <>
            <ReactCrop
              aspect={1}
              crop={crop}
              onChange={setCrop}
              onComplete={handleComplete}
            >
              <img src={inputImage} />
            </ReactCrop>

            <div className="action-btns">
              <Button
                className="btn btn-primary"
                onClick={handleSelect}
              >Select</Button>

              <Button
                className="btn btn-danger"
                onClick={handleRemove}
              >Remove</Button>
            </div>
          </>
        ) : (
          <div className="crop-image-content__input">
            <input
              type='file'
              accept='image/*'
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CropImage;