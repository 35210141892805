import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

// Hocs
import { Global } from 'hocs/global.hoc';
import { Public } from 'hocs/public.hoc';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { login } from 'store/global/action';

// Helpers
import { setToken } from 'helpers/common.helper';

import './Login.scss';

const Login = (props: any) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    const valid = await form.validateFields();
    if (!valid) return;

    try {
      setLoading(true);
      const res: any = await unwrapResult(await dispatch(login(values)));
      setToken(res.token);
      navigate('/');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="login-content">
      <h3>Beem | Login</h3>

      <div className="login-content__form">
        <Form
          name="basic"
          onFinish={onSubmit}
          autoComplete="off"
          layout="vertical"
          className="default"
          form={form}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              { pattern: new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'), message: 'Please enter valid email' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              { min: 8, message: 'Password must be at least 8 characters' },
              { max: 100, message: 'Password must be no more than 100 characters' },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >Sign In</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Global(Public(Login));
