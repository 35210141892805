import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';

// Store
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { createAdmin, emitEvent } from 'store/global/action';

// Constants
import { GLOBAL_EVENT } from 'constants/common.constant';

export interface IProps {
  onSuccess: () => void;
}

const CreateManager = ({ onSuccess }: IProps) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    const valid = await form.validateFields();
    if (!valid) return;

    try {
      setLoading(true);
      await unwrapResult(await dispatch(createAdmin(values)));
      form.resetFields();
      message.success('Created admin successfully');
      onSuccess();
      dispatch(emitEvent({ type: GLOBAL_EVENT.RELOAD_ADMIN }));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="create-manager-content custom-modal-content">
      <div className="create-manager-content__form">
        <Form
          name="basic"
          onFinish={onSubmit}
          autoComplete="off"
          layout="vertical"
          className="default"
          form={form}
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              { required: true, message: 'Full name is required' },
              { min: 3, message: 'Full name must be at least 3 characters' },
              { max: 100, message: 'Full name must be no more than 100 characters' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              { pattern: new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'), message: 'Please enter valid email' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              { min: 8, message: 'Password must be at least 8 characters' },
              { max: 100, message: 'Password must be no more than 100 characters' },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              loading={loading}
            >Submit</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CreateManager;
