import { configureStore } from '@reduxjs/toolkit';
import { reducer as global } from 'store/global';

const store = configureStore({
  reducer: {
    global,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;