import React, { useEffect, useState } from 'react';

// Store
import { useAppDispatch } from 'store/hooks';
import { unwrapResult } from '@reduxjs/toolkit';

// Helpers
import {
  getCurrentUser,
  storeMe,
} from 'store/global/action';

export const Global = (Component: any) => {
  const WithGlobal = (props: any) => {
    const dispatch = useAppDispatch();

    const [me, setMe] = useState<any>(undefined);

    const fetchCurrentUser = async () => {
      try {
        const res: any = await unwrapResult(await dispatch(getCurrentUser()));
        setMe(res);
        dispatch(storeMe(res));
      } catch (e) {
        setMe(null);
      }
    }

    useEffect(() => {
      fetchCurrentUser();
    }, []);

    return <Component props={{ ...props, me }} />;
  }

  return WithGlobal;
};
